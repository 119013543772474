<template>
	<div class="py-2 pagination" v-if="total_data">
		<nav class="block">
			<ul class="flex pl-0 rounded list-none flex-wrap">
				<li
					v-bind:key="page"
					v-for="page in [...Array(getPageLength()).keys()]"
					:class="{ active: active_page === page + 1 }"
				>
					<button
						v-on:click="$emit('clicked', page + 1)"
						class="
							first:ml-0
							text-xs
							font-semibold
							flex
							w-8
							h-8
							mx-1
							p-0
							rounded-full
							items-center
							justify-center
							leading-tight
							relative
							text-white
						"
					>
						{{ page + 1 }}
					</button>
				</li>
			</ul>
		</nav>
	</div>
</template>

<script>
export default {
	methods: {
		getPageLength() {
			return Math.ceil(this.total_data / this.page_length)
		},
	},
	emits: ['clicked'],
	props: ['active_page', 'total_data', 'page_length'],
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/template/partial/_pagination.scss';
</style>