import footer_api from '../../../apis/footer'

export default {
	async getFooters({ commit }) {
		let footer_data = await footer_api.getFooter()
		footer_data = footer_data.data.data[0]

		commit('setFooters', footer_data)
	},
}
