<template>
	<div class="modal-mask" v-on:click="$emit('close')" v-if="data">
		<div class="modal-wrapper">
			<div class="modal-container" @click.stop="">
				<div class="modal-header">{{ data.title }}</div>
				<div class="modal-body">
					<span class="description" v-html="data.description"></span>
					<div class="our-service">
						<p class="title">Jasa Kami</p>
						<div
							class="list ql-editor"
							v-html="data.services"
						></div>
					</div>
					<div
						class="project-experience"
						v-if="!isEmpty(data.projects)"
					>
						<p class="title">Pengalaman Proyek</p>
						<div class="slider">
							<span class="slider-navigation prev"></span>
							<swiper
								class="banner-slider"
								:preloadImages="true"
								:space-between="20"
								:autoplay="{
									delay: 2500,
									disableOnInteraction: false,
								}"
								:navigation="{
									nextEl: '.slider-navigation.next',
									prevEl: '.slider-navigation.prev',
								}"
								freeMode="true"
								:breakpoints="{
									600: {
										slidesPerView: 1,
									},
									768: {
										slidesPerView: 2,
									},
									960: {
										slidesPerView: 3,
									},
								}"
							>
								<swiper-slide
									v-bind:key="`project-${slide.project_id}`"
									v-for="slide in data.projects"
									class="banner-slide"
								>
									<div class="banner-wrapper">
										<img class="image" :src="slide.image" />
										<div class="detail">
											<p class="company">
												{{ slide.company }}
											</p>
											<p class="title">
												{{ slide.project_name }}
											</p>
											<p class="location">
												{{ slide.project_loc }}
											</p>
											<p class="time">
												{{ slide.project_period }}
											</p>
										</div>
									</div>
								</swiper-slide>
							</swiper>
							<span class="slider-navigation next"></span>
						</div>
					</div>
				</div>
				<div class="modal-footer flex justify-center mt-8">
					<button class="btn-close mb-4" v-on:click="$emit('close')">
						Tutup
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { Swiper, SwiperSlide } from 'swiper/vue'
	import SwiperCore, { Autoplay, Navigation } from 'swiper'
	SwiperCore.use([Autoplay, Navigation])
	import 'swiper/css'
	import 'swiper/css/navigation'

	export default {
		components: {
			Swiper,
			SwiperSlide,
		},
		emits: ['close'],
		props: ['data'],
	}
</script>

<style lang="scss" scoped>
	@import '../../assets/scss/template/partial/_modal.scss';
</style>
