import career_api from '../../../apis/career'

export default {
	async getCareers({ commit, rootGetters }) {
		const selected_language = rootGetters['GLOBAL/selected_language']
		let careers = await career_api.getCareers(selected_language)

		const api_uri =
			process.env.APP_ENV === 'development'
				? process.env.VUE_APP_API_URL_DEV
				: process.env.VUE_APP_API_URL_PROD

		const temp = {}

		if (careers.data.data.length > 0) {
			careers.data.data = careers.data.data
				.map((item) => ({
					...item,
					image: `${api_uri}${item.image}`,
				}))
				.forEach((career) => {
					temp[career.id] = career
				})
		}

		commit('setCareers', temp)
	},
	setSelectedCareer({ commit, getters }, id) {
		const selected_career = getters.careers[id]

		commit('setSelectedCareer', selected_career)
	},
}
