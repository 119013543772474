import { createStore } from 'vuex'
import about from './modules/about'
import banner from './modules/banner'
import career from './modules/career'
import catalog from './modules/catalog'
import category from './modules/category'
import contact from './modules/contact'
import footer from './modules/footer'
import global from './modules/global'
import our_certificate from './modules/our-certificate'
import our_client from './modules/our-client'
import our_specialist from './modules/our-specialist'
import our_team from './modules/our-team'
import product from './modules/product'
import vision_mission from './modules/vision-mission'
import why_edim from './modules/why-edim'

const store = createStore({
	modules: {
		ABOUT: about,
		BANNER: banner,
		CAREER: career,
		CATALOG: catalog,
		CATEGORY: category,
		CONTACT: contact,
		FOOTER: footer,
		GLOBAL: global,
		OUR_CERTIFICATE: our_certificate,
		OUR_CLIENT: our_client,
		OUR_SPECIALIST: our_specialist,
		OUR_TEAM: our_team,
		PRODUCT: product,
		VISION_MISSION: vision_mission,
		WHY_EDIM: why_edim,
	},
})

export default store
