<template>
	<div class="home">
		<main>
			<section class="why" v-if="!is_loading.why">
				<template v-if="!isEmpty(state_why_edim)">
					<div class="wrapper flex">
						<img :src="state_why_edim.image" />
						<div class="text">
							<h2 class="title">
								{{ home_menu.why[state_selected_language] }}
							</h2>
							<p
								class="description ql-editor"
								v-html="state_why_edim.description"
							></p>
						</div>
					</div>
				</template>
				<div v-else class="no-data"></div>
			</section>
			<div class="loading" v-else>
				<i class="fas fa-circle-notch fa-spin"></i>
			</div>
			<section class="specialist" v-if="!is_loading.specialist">
				<template v-if="!isEmpty(state_specialists)">
					<transition name="modal">
						<my-modal
							v-if="
								!is_specialist_hidden &&
								!isEmpty(state_selected_specialist)
							"
							@close="closeModalSpecialist()"
							:data="state_selected_specialist"
						>
						</my-modal>
					</transition>
					<h2 class="title">
						{{ home_menu.specialist[state_selected_language] }}
					</h2>
					<div class="slider">
						<span class="slider-navigation prev"></span>
						<swiper
							class="banner-slider"
							:preloadImages="true"
							:space-between="0"
							:autoplay="{
								delay: 2500,
								disableOnInteraction: false,
							}"
							:navigation="{
								nextEl: '.slider-navigation.next',
								prevEl: '.slider-navigation.prev',
							}"
							:breakpoints="{
								600: {
									slidesPerView: 1,
								},
								768: {
									slidesPerView: 2,
								},
								1024: {
									slidesPerView: 2,
								},
								1280: {
									slidesPerView: 3,
								},
							}"
						>
							<swiper-slide
								v-bind:key="slide"
								v-for="slide in state_specialists"
								class="banner-slide"
								@click.stop="openModalSpecialist(slide.id)"
							>
								<div
									class="banner-wrapper"
									:id="`specialist-${slide.id}`"
								>
									<img
										class="banner-image"
										:src="slide.image"
									/>
									<span class="title">{{ slide.title }}</span>
								</div>
							</swiper-slide>
						</swiper>
						<span class="slider-navigation next"></span>
					</div>
				</template>
				<div v-else class="no-data"></div>
			</section>
			<div class="loading" v-else>
				<i class="fas fa-circle-notch fa-spin"></i>
			</div>
			<section class="choosen-product" v-if="!is_loading.choosen_product">
				<template v-if="!isEmpty(state_selected_products)">
					<div class="header">
						<h2 class="title">
							{{
								home_menu.selected_product[
									state_selected_language
								]
							}}
						</h2>
						<router-link to="/product">
							<p class="see-all">
								{{
									home_menu.selected_product_button[
										state_selected_language
									]
								}}
							</p>
						</router-link>
					</div>
					<div class="product-col">
						<div
							v-bind:key="slide"
							v-for="slide in state_selected_products"
							class="product-wrapper"
						>
							<img class="image" :src="slide.images[0]" />
							<p class="category">{{ slide.category_name }}</p>
							<p class="title">{{ slide.name }}</p>
							<span
								class="btn-detail"
								@click="setSelectedProduct(slide.id)"
							>
								<p class="text">Detail</p>
							</span>
						</div>
					</div>
				</template>
				<div v-else class="no-data"></div>
			</section>
			<div class="loading" v-else>
				<i class="fas fa-circle-notch fa-spin"></i>
			</div>
			<section class="our-client" v-if="!is_loading.our_client">
				<template v-if="!isEmpty(state_our_clients)">
					<h2 class="title">
						{{ home_menu.our_client[state_selected_language] }}
					</h2>
					<div class="slider">
						<span class="slider-navigation prev"></span>
						<swiper
							class="banner-slider"
							:space-between="0"
							:autoplay="{
								delay: 2500,
								disableOnInteraction: false,
							}"
							:navigation="{
								nextEl: '.slider-navigation.next',
								prevEl: '.slider-navigation.prev',
							}"
							:breakpoints="{
								600: {
									slidesPerView: 1,
								},
								768: {
									slidesPerView: 3,
								},
								1024: {
									slidesPerView: 3,
								},
								1280: {
									slidesPerView: 4,
								},
							}"
						>
							<swiper-slide
								v-bind:key="`client-${slide.id}`"
								v-for="slide in state_our_clients"
								class="banner-slide"
							>
								<div class="banner-wrapper">
									<img
										class="banner-image"
										:src="slide.image"
									/>
								</div>
							</swiper-slide>
						</swiper>
						<span class="slider-navigation next"></span>
					</div>
				</template>
				<div v-else class="no-data"></div>
			</section>
			<div class="loading" v-else>
				<i class="fas fa-circle-notch fa-spin"></i>
			</div>
		</main>
	</div>
</template>
<script>
	import { home } from '../config/menu.js'
	import { Swiper, SwiperSlide } from 'swiper/vue'
	import SwiperCore, { Autoplay, Navigation } from 'swiper'
	SwiperCore.use([Autoplay, Navigation])
	import 'swiper/css'
	import 'swiper/css/navigation'
	import { mapGetters } from 'vuex'

	// components
	import modal from '../components/partial/_Modal.vue'

	export default {
		data() {
			return {
				is_loading: {
					choosen_product: true,
					our_client: true,
					specialist: true,
					why: true,
				},
				is_specialist_hidden: true,
				home_menu: home,
			}
		},
		components: {
			Swiper,
			SwiperSlide,
			'my-modal': modal,
		},
		computed: {
			...mapGetters({
				state_banners: 'BANNER/banners',
				state_our_clients: 'OUR_CLIENT/our_clients',
				state_selected_language: 'GLOBAL/selected_language',
				state_selected_products: 'PRODUCT/selected_products',
				state_selected_specialist: 'OUR_SPECIALIST/selected_specialist',
				state_specialists: 'OUR_SPECIALIST/our_specialists',
				state_why_edim: 'WHY_EDIM/why_edim',
			}),
		},
		watch: {
			state_selected_language: {
				handler(new_val, old_val) {
					if (new_val !== old_val) {
						this.init()
					}
				},
				deep: true,
			},
		},
		methods: {
			closeModalSpecialist() {
				this.is_specialist_hidden = true
				this.$store.dispatch('OUR_SPECIALIST/removeSelectedSpecialist')
			},
			init() {
				this.$store.dispatch('WHY_EDIM/getWhyEdim')
				this.is_loading.why = false
				this.$store.dispatch('OUR_SPECIALIST/getOurSpecialists')
				this.is_loading.specialist = false
				this.$store.dispatch('OUR_CLIENT/getOurClients')
				this.is_loading.our_client = false
				this.$store.dispatch('PRODUCT/getSelectedProducts')
				this.is_loading.choosen_product = false
			},
			openModalSpecialist(id) {
				this.is_specialist_hidden = false
				this.$store.dispatch('OUR_SPECIALIST/setSelectedSpecialist', id)
			},
			setSelectedProduct(id) {
				this.$store
					.dispatch('PRODUCT/setSelectedProduct', id)
					.then(() => {
						this.$router.push({
							name: 'product-detail',
							params: {
								slug: id,
							},
						})
					})
			},
		},
		mounted() {
			this.init()
		},
	}
</script>

<style lang="scss" scoped>
	@import '../assets/scss/home.scss';
</style>
