import home from '../views/home.vue'
import about from '../views/about.vue'
import career from '../views/career/career.vue'
import career_detail from '../views/career/career-detail.vue'
import contact from '../views/contact.vue'
import product from '../views/product/product.vue'
import product_detail from '../views/product/product-detail.vue'

const routes = [
	{
		path: '/',
		name: 'home',
		component: home,
		meta: {
			with_banner: true,
		},
	},
	{
		path: '/about',
		name: 'about',
		component: about,
		meta: {
			with_banner: true,
		},
	},
	{
		path: '/career',
		name: 'career',
		component: career,
		meta: {
			with_banner: true,
		},
	},
	{
		path: '/career/:id',
		name: 'career-detail',
		component: career_detail,
		meta: {
			with_banner: false,
		},
	},
	{
		path: '/contact',
		name: 'contact',
		component: contact,
		meta: {
			with_banner: true,
		},
	},
	{
		path: '/product',
		name: 'product',
		component: product,
		meta: {
			with_banner: true,
		},
	},
	{
		path: '/product/:slug',
		name: 'product-detail',
		component: product_detail,
		meta: {
			with_banner: false,
		},
	},
]

export default routes
