<template>
	<footer class="relative">
		<div
			class="info-button-wrapper"
			:class="{ hide: is_info_btn_hidden }"
			v-if="!isEmpty(state_contact)"
		>
			<button class="info-button" @click="openModalInfo()">
				<img :src="info_button" />
				{{ footer_menu.info[state_selected_language] }}
			</button>
			<span @click="is_info_btn_hidden = true" class="close-button"
				>x</span
			>
		</div>
		<transition name="modal">
			<my-modal-info
				v-if="!is_info_hidden"
				@close="is_info_hidden = true"
				:contact_data="state_contact"
			>
			</my-modal-info>
		</transition>
		<div class="mx-auto px-4 footer-wrapper" v-if="!isEmpty(state_footers)">
			<div class="flex flex-wrap">
				<div class="w-full lg:w-6/12 px-4 pl-0">
					<img class="logo" :src="edim_logo" />
					<h5 class="text-lg mt-0 mb-2 description">
						{{ state_footers.description }}
					</h5>
				</div>
				<div class="w-full lg:w-6/12 px-4 contact-col pr-0">
					<div class="flex flex-wrap items-top mb-6">
						<div class="w-full lg:w-6/12 px-4">
							<div class="contact">
								<span class="title">{{
									footer_menu.phone[state_selected_language]
								}}</span>
								<span class="value">{{
									state_footers.phone
								}}</span>
							</div>
							<div class="contact">
								<span class="title">Email</span>
								<span class="value">{{
									state_footers.email
								}}</span>
							</div>
						</div>
						<div class="w-full lg:w-6/12 px-4">
							<div class="contact">
								<span class="title">{{
									footer_menu.website[state_selected_language]
								}}</span>
								<span class="value">{{
									state_footers.site
								}}</span>
							</div>
							<div class="contact">
								<span class="title">{{
									footer_menu.address[state_selected_language]
								}}</span>
								<span class="value">
									{{ state_footers.address }}
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<hr class="my-6 border-white" />
			<div
				class="
					flex flex-wrap
					items-center
					md:justify-between
					justify-center
				"
			>
				<div class="w-full mx-auto flex flex-wrap items-center">
					<div class="w-full lg:w-6/12">
						<span class="copyright"
							>copyright © edim {{ date }}</span
						>
					</div>
					<div class="w-full lg:w-6/12 flex justify-end media-col">
						<!-- <a
							href="https://linkedin.com"
							class="media-link"
							target="_blank"
						>
							<img :src="linkedin_logo" />
						</a>
						<a
							href="https://instagram.com"
							class="media-link"
							target="_blank"
						>
							<img :src="ig_logo" />
						</a> -->
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>
<script>
// assets
import { footer } from '../config/menu.js'
import { mapGetters } from 'vuex'
import edim_logo from '../assets/img/edim-logo.png'
import ig_logo from '../assets/img/logo-instagram.svg'
import linkedin_logo from '../assets/img/logo-linkedin.svg'
import info_button from '../assets/img/info-button.svg'

// components
import modal from './partial/_Modal-info.vue'

export default {
	components: {
		'my-modal-info': modal,
	},
	data() {
		return {
			date: new Date().getFullYear(),
			edim_logo: edim_logo,
			footer_menu: footer,
			ig_logo: ig_logo,
			info_button: info_button,
			linkedin_logo: linkedin_logo,
			is_info_hidden: true,
			is_info_btn_hidden: false,
		}
	},
	computed: {
		...mapGetters({
			state_contact: 'CONTACT/contact_data',
			state_footers: 'FOOTER/footers',
			state_selected_language: 'GLOBAL/selected_language',
		}),
	},
	methods: {
		openModalInfo() {
			this.is_info_hidden = false
		},
	},
	mounted() {
		this.$store.dispatch('CONTACT/getContact')
		this.$store.dispatch('FOOTER/getFooters')
	},
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/template/_footer.scss';
</style>
