import vision_mission_api from '../../../apis/vision-mission'

export default {
	async getVisionMission({ commit, rootGetters }) {
		const selected_language = rootGetters['GLOBAL/selected_language']
		const mission = await vision_mission_api.getMission(selected_language)
		const vision = await vision_mission_api.getVision(selected_language)

		commit('setMissionVision', {
			mission: mission.data.data.description,
			vision: vision.data.data.description,
		})
	},
}
