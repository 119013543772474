<template>
	<div id="app">
		<Header></Header>
		<router-view />
		<Footer></Footer>
	</div>
</template>

<script>
import Footer from './components/_Footer.vue'
import Header from './components/_Header.vue'

export default {
	components: {
		Footer,
		Header,
	},
}
</script>
