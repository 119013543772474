import why_edim_api from '../../../apis/why-edim'

export default {
	async getWhyEdim({ commit, rootGetters }) {
		const selected_language = rootGetters['GLOBAL/selected_language']
		const api_uri =
			process.env.APP_ENV === 'development'
				? process.env.VUE_APP_API_URL_DEV
				: process.env.VUE_APP_API_URL_PROD

		let why_edim = await why_edim_api.getWhyEdim(selected_language)

		if (why_edim.data.data) {
			why_edim.data.data = why_edim.data.data[0]
			why_edim.data.data = {
				...why_edim.data.data,
				image: `${api_uri}${why_edim.data.data.image}`,
			}
		}

		commit('setWhyEdim', why_edim.data.data)
	},
}
