export default {
	setProduct(state, product) {
		state.product = product
	},
	setProducts(state, products) {
		state.products = products
	},
	setSelectedProducts(state, products) {
		state.selected_products = products
	},
}
