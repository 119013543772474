<template>
	<div class="career">
		<main>
			<section v-if="!is_loading" class="vacancy">
				<h2 class="title">
					{{ career_menu.info[state_selected_language] }}
				</h2>
				<template v-if="!isEmpty(state_careers)">
					<div class="slider">
						<span class="slider-navigation prev"></span>
						<swiper
							class="banner-slider"
							:preloadImages="true"
							:space-between="30"
							:autoplay="{
								delay: 2500,
								disableOnInteraction: false,
							}"
							:navigation="{
								nextEl: '.slider-navigation.next',
								prevEl: '.slider-navigation.prev',
							}"
							:breakpoints="{
								600: {
									slidesPerView: 1,
								},
								768: {
									slidesPerView: 2,
								},
								1024: {
									slidesPerView: 2,
								},
								1280: {
									slidesPerView: 3,
								},
								1366: {
									slidesPerView: 4,
								},
							}"
						>
							<swiper-slide
								v-bind:key="`career-${slide.id}`"
								v-for="slide in state_careers"
								class="banner-slide"
								@click.stop="setSelectedCareer(slide.id)"
							>
								<div class="banner-wrapper">
									<img
										class="banner-image"
										:src="slide.image"
									/>
									<div class="detail-wrapper">
										<span class="title">{{
											slide.position
										}}</span>
										<span class="slot"
											>{{ slide.quantity }}
											{{
												career_menu.position[
													state_selected_language
												]
											}}</span
										>
										<span class="requirement">{{
											slide.description
										}}</span>
										<span class="gender">{{
											slide.sex
										}}</span>
										<span class="btn-detail">
											<p class="text">
												{{
													career_menu.show_detail[
														state_selected_language
													]
												}}
											</p>
										</span>
									</div>
								</div>
							</swiper-slide>
						</swiper>
						<span class="slider-navigation next"></span>
					</div>
				</template>
				<div v-else class="no-data"></div>
			</section>
			<div class="loading" v-else>
				<i class="fas fa-circle-notch fa-spin"></i>
			</div>
		</main>
	</div>
</template>
<script>
	import { career } from '../../config/menu.js'
	import { mapGetters } from 'vuex'
	import { Swiper, SwiperSlide } from 'swiper/vue'
	import SwiperCore, { Autoplay, Navigation } from 'swiper'
	SwiperCore.use([Autoplay, Navigation])
	import 'swiper/css'
	import 'swiper/css/navigation'

	export default {
		components: {
			Swiper,
			SwiperSlide,
		},
		data() {
			return {
				career_menu: career,
				is_loading: true,
			}
		},
		computed: {
			...mapGetters({
				state_careers: 'CAREER/careers',
				state_selected_language: 'GLOBAL/selected_language',
			}),
		},
		watch: {
			state_selected_language: {
				handler(new_val, old_val) {
					if (new_val !== old_val) {
						this.$store.dispatch('CAREER/getCareers')
					}
				},
				deep: true,
			},
		},
		methods: {
			setSelectedCareer(id) {
				this.$store
					.dispatch('CAREER/setSelectedCareer', id)
					.then(() => {
						this.$router.push({
							name: 'career-detail',
							params: {
								id: id,
							},
						})
					})
			},
		},
		mounted() {
			this.$store
				.dispatch('CAREER/getCareers')
				.then(() => (this.is_loading = false))
				.catch(() => (this.is_loading = false))
		},
	}
</script>

<style lang="scss" scoped>
	@import '../../assets/scss/career/career.scss';
</style>
