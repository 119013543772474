export default {
	product(state) {
		return state.product
	},
	products(state) {
		return state.products
	},
	selected_products(state) {
		return state.selected_products
	},
}
