<template>
	<div class="career-detail" v-if="state_selected_career">
		<main>
			<section class="banner-still">
				<img :src="banner_image" />
			</section>
			<section class="vacancy">
				<h2 class="title">{{ state_selected_career.title }}</h2>
				<div class="detail">
					<div class="responsibility">
						<p class="title">
							{{
								career_menu.responsibility[
									state_selected_language
								]
							}}
						</p>
						<div
							class="list ql-editor"
							v-html="state_selected_career.responsibility"
						></div>
					</div>
					<div class="requirement">
						<p class="title">
							{{
								career_menu.requirement[state_selected_language]
							}}
						</p>
						<div
							class="list ql-editor"
							v-html="state_selected_career.requirement"
						></div>
					</div>
					<div class="facility">
						<p class="title">
							{{ career_menu.facility[state_selected_language] }}
						</p>
						<div
							class="list ql-editor"
							v-html="state_selected_career.facility"
						></div>
					</div>
				</div>
				<div class="another-detail">
					{{ career_menu.email_to[state_selected_language] }}
					{{ state_selected_career.mail_to }}
				</div>
			</section>
		</main>
	</div>
	<div class="loading" v-else>
		<i class="fas fa-circle-notch fa-spin"></i>
	</div>
</template>
<script>
	import { career } from '../../config/menu.js'
	import { mapGetters } from 'vuex'
	//asssets
	import banner from '../../assets/img/banner.png'

	export default {
		data() {
			return {
				banner_image: banner,
				career_menu: career,
				router: null,
				store: null,
			}
		},
		computed: {
			...mapGetters({
				state_selected_career: 'CAREER/selected_career',
				state_selected_language: 'GLOBAL/selected_language',
			}),
		},
		watch: {
			state_selected_language: {
				handler(new_val, old_val) {
					if (new_val !== old_val) {
						this.$store.dispatch('CAREER/getCareers').then(() => {
							this.$store.dispatch(
								'CAREER/setSelectedCareer',
								this.$router.currentRoute._value.params.id,
							)
						})
					}
				},
				deep: true,
			},
		},
		mounted() {
			if (!this.state_selected_career) {
				this.$store.dispatch('CAREER/getCareers').then(() => {
					this.$store.dispatch(
						'CAREER/setSelectedCareer',
						this.$router.currentRoute._value.params.id,
					)
				})
			}
		},
	}
</script>

<style lang="scss" scoped>
	@import '../../assets/scss/career/career-detail.scss';
</style>
