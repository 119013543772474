import product_api from '../../../apis/product'

export default {
	async getProducts({ commit, rootGetters }) {
		const selected_language = rootGetters['GLOBAL/selected_language']
		const products = await product_api.getProducts(selected_language)

		const api_uri =
			process.env.APP_ENV === 'development'
				? process.env.VUE_APP_API_URL_DEV
				: process.env.VUE_APP_API_URL_PROD

		const temp = []

		if (products.data.data.length > 0) {
			products.data.data = products.data.data
				.map((item) => {
					return {
						...item,
						images: item.images.map(
							(image) => `${api_uri}${image.image}`,
						),
					}
				})
				.forEach((product) => {
					temp.push(product)
				})
		}

		commit('setProducts', temp)
	},
	async getSelectedProducts({ commit, rootGetters }) {
		const selected_language = rootGetters['GLOBAL/selected_language']
		const products = await product_api.getProducts(selected_language)

		const api_uri =
			process.env.APP_ENV === 'development'
				? process.env.VUE_APP_API_URL_DEV
				: process.env.VUE_APP_API_URL_PROD

		const temp = {}

		if (products.data.data.length > 0) {
			products.data.data = products.data.data
				.filter((item_filter) => item_filter.is_featured > 0)
				.map((item) => {
					return {
						...item,
						images: item.images.map(
							(image) => `${api_uri}${image.image}`,
						),
					}
				})
				.forEach((product) => {
					temp[product.id] = product
				})
		}

		commit('setSelectedProducts', temp)
	},
	async setSelectedProduct({ commit, rootGetters }, id) {
		const selected_language = rootGetters['GLOBAL/selected_language']
		const product = await product_api.getProduct(id, selected_language)

		const api_uri =
			process.env.APP_ENV === 'development'
				? process.env.VUE_APP_API_URL_DEV
				: process.env.VUE_APP_API_URL_PROD

		let temp = {}

		if (product.data && product.data.data) {
			temp = product.data.data
			temp.images = temp.images.map((image) => `${api_uri}${image.image}`)
		}

		commit('setProduct', temp)
	},
}
