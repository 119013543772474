import axios from 'axios'

const api_config = axios.create({
	baseURL:
		process.env.APP_ENV === 'development'
			? process.env.VUE_APP_API_URL_DEV
			: process.env.VUE_APP_API_URL_PROD,
})

export default api_config
