import banner_api from '../../../apis/banner'

export default {
	async getBanners({ commit }) {
		let banners = await banner_api.getBanners()
		const api_uri =
			process.env.APP_ENV === 'development'
				? process.env.VUE_APP_API_URL_DEV
				: process.env.VUE_APP_API_URL_PROD

		if (banners.data.data.length > 0) {
			banners.data.data = banners.data.data.map((item) => ({
				...item,
				image: `${api_uri}${item.image}`,
			}))
		}

		commit('setBanners', banners.data.data)
	},
}
