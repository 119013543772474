module.exports = {
	about: {
		about_us: {
			eng: 'About Us',
			ind: 'Tentang Kami',
		},
		certificate: {
			eng: 'Certificate',
			ind: 'Sertifikasi',
		},
		mission: {
			eng: 'Our Mission',
			ind: 'Misi Kami',
		},
		our_team: {
			eng: 'Our Team',
			ind: 'Tim Kami',
		},
		specialist: {
			eng: 'Our Specialist',
			ind: 'Spesialis Kami',
		},
		vision: {
			eng: 'Our Vision',
			ind: 'Visi Kami',
		},
	},
	detail_product: {
		description: {
			eng: 'Description',
			ind: 'Deskripsi',
		},
		function: {
			eng: 'Function',
			ind: 'Kegunaan',
		},
		height: {
			eng: 'Height',
			ind: 'Tinggi',
		},
		length: {
			eng: 'Length',
			ind: 'Panjang',
		},
		material: {
			eng: 'Material',
			ind: 'Bahan',
		},
		next: {
			eng: 'Next',
			ind: 'Selanjutnya',
		},
		prev: {
			eng: 'Previous',
			ind: 'Sebelumnya',
		},
		title: {
			eng: 'Product Detail',
			ind: 'Detail Produk',
		},
		video: {
			eng: 'Product video',
			ind: 'Video produk',
		},
		weight: {
			eng: 'Weight',
			ind: 'Berat',
		},
	},
	career: {
		email_to: {
			eng: 'Send your application letter to this email:',
			ind: 'Kirimkan surat lamaran anda via email ke:',
		},
		facility: {
			eng: 'Facility:',
			ind: 'Fasilitas:',
		},
		info: {
			eng: 'Career Information',
			ind: 'Informasi Karir',
		},
		position: {
			eng: 'Position',
			ind: 'Posisi',
		},
		requirement: {
			eng: 'Requirement:',
			ind: 'Persyaratan:',
		},
		responsibility: {
			eng: 'Duties and Responsibility:',
			ind: 'Tugas dan Tanggung Jawab:',
		},
		show_detail: {
			eng: 'Detail',
			ind: 'Lihat',
		},
	},
	contact: {
		contact: {
			eng: 'Contact',
			ind: 'Kontak',
		},
		location: {
			eng: 'Show Location',
			ind: 'Lihat Lokasi',
		},
	},
	footer: {
		address: {
			eng: 'Address',
			ind: 'Alamat',
		},
		info: {
			detail: {
				address: {
					eng: 'Office',
					ind: 'Kantor',
				},
				phone: {
					eng: 'Phone',
					ind: 'Telepon',
				},
				title: {
					eng: 'Information',
					ind: 'Informasi',
				},
			},
			eng: 'Information',
			ind: 'Informasi',
		},
		phone: {
			eng: 'Phone',
			ind: 'Telepon',
		},
		website: {
			eng: 'Website',
			ind: 'Situs Web',
		},
	},
	home: {
		our_client: {
			eng: 'Our Client',
			ind: 'Klien Kami',
		},
		selected_product: {
			eng: 'Selected Product',
			ind: 'Produk Unggulan',
		},
		selected_product_button: {
			eng: 'More',
			ind: 'Lihat semua',
		},
		specialist: {
			eng: 'Our Specialist',
			ind: 'Spesialis Kami',
		},
		why: {
			eng: 'Why EDIM ?',
			ind: 'Mengapa EDIM ?',
		},
	},
	navbar: {
		about: {
			eng: 'About',
			ind: 'Tentang',
		},
		career: {
			eng: 'Career',
			ind: 'Karir',
		},
		contact: {
			eng: 'Contact',
			ind: 'Kontak',
		},
		home: {
			eng: 'Home',
			ind: 'Beranda',
		},
		product: {
			eng: 'Product',
			ind: 'Produk',
		},
	},
	product: {
		all_product: {
			eng: 'All Product',
			ind: 'Semua Produk',
		},
		category: {
			eng: 'Category',
			ind: 'Kategori',
		},
		download: {
			eng: 'Download catalog',
			ind: 'Unduh katalog',
		},
		no_product: {
			eng: 'No product.',
			ind: 'Tidak ada produk.',
		},
		title: {
			eng: 'Product',
			ind: 'Produk',
		},
	},
}
