<template>
	<div class="product-detail" v-if="can_show">
		<main>
			<section class="detail">
				<div class="section-header">
					<h2 class="title">
						{{ detail_menu.title[state_selected_language] }}
					</h2>
					<div
						class="navigation-wrapper"
						v-if="!isEmpty(state_products)"
					>
						<button
							class="btn-navigation prev"
							@click="navigateTo(prev_product)"
							v-if="prev_product"
						>
							<i class="fa fa-arrow-left"></i>
							{{ detail_menu.prev[state_selected_language] }}
						</button>
						<button
							class="btn-navigation next"
							@click="navigateTo(next_product)"
							v-if="next_product"
						>
							{{ detail_menu.next[state_selected_language] }}
							<i class="fa fa-arrow-right"></i>
						</button>
					</div>
				</div>
				<div class="section-body">
					<div class="product-overview">
						<div class="section image">
							<div class="active-image">
								<img :src="active_image" />
							</div>
							<swiper
								class="image-slider"
								:loop="true"
								:slides-per-view="3"
								:space-between="30"
								:navigation="true"
								slideToClickedSlide="true"
								@slideChange="handleActiveImage"
							>
								<swiper-slide
									v-bind:key="image"
									v-for="image in state_product.images"
									class="image-slide"
								>
									<div class="banner-wrapper">
										<img class="image" :src="image" />
									</div>
								</swiper-slide>
							</swiper>
						</div>
						<div class="section description">
							<p class="category">
								{{ state_product.category_name }}
							</p>
							<p class="title">{{ state_product.name }}</p>
							<div
								class="overview ql-editor"
								v-html="state_product.description"
							></div>
						</div>
					</div>
					<div class="tab-col">
						<button
							class="tab"
							:class="{ active: is_tab_description }"
							@click="is_tab_description = true"
						>
							{{
								detail_menu.description[state_selected_language]
							}}
						</button>
						<button
							class="tab"
							:class="{ active: !is_tab_description }"
							@click="is_tab_description = false"
							v-if="
								state_product.video &&
								state_product.video.length > 0
							"
						>
							{{ detail_menu.video[state_selected_language] }}
						</button>
					</div>
					<div
						class="tab-content description"
						v-if="is_tab_description"
					>
						<div
							class="overview ql-editor"
							v-html="state_product.detail_specification"
						></div>
					</div>
					<div
						v-else-if="
							state_product.video &&
							state_product.video.length > 0
						"
						class="tab-content video"
					>
						<div class="active-video">
							<iframe
								frameborder="0"
								allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowfullscreen
								:src="active_video.url"
								:title="active_video.title"
							>
							</iframe>
						</div>
						<div class="slider">
							<span class="slider-navigation prev"></span>
							<swiper
								class="banner-slider"
								:loop="true"
								:slides-per-view="3"
								:space-between="30"
								@slideChange="handleActiveVideo"
								slideToClickedSlide="true"
								:navigation="{
									nextEl: '.slider-navigation.next',
									prevEl: '.slider-navigation.prev',
								}"
							>
								<swiper-slide
									v-bind:key="video"
									v-for="video in state_product.videos"
									class="video-slide"
								>
									<div class="video-wrapper">
										<img
											class="video-thumbnail"
											:src="`http://img.youtube.com/vi/${video.video}/1.jpg`"
										/>
									</div>
								</swiper-slide>
							</swiper>
							<span class="slider-navigation next"></span>
						</div>
					</div>
				</div>
			</section>
		</main>
	</div>
	<div class="loading" v-else>
		<i class="fas fa-circle-notch fa-spin"></i>
	</div>
</template>
<script>
	import { detail_product } from '../../config/menu.js'
	import { mapGetters } from 'vuex'
	import { Swiper, SwiperSlide } from 'swiper/vue'
	import SwiperCore, { Autoplay, Navigation } from 'swiper'
	SwiperCore.use([Autoplay, Navigation])
	import 'swiper/css'
	import 'swiper/css/navigation'

	export default {
		components: {
			Swiper,
			SwiperSlide,
		},
		data() {
			return {
				active_image: null,
				active_video: null,
				can_show: false,
				detail_menu: detail_product,
				image_swiper: null,
				next_product: null,
				prev_product: null,
				is_tab_description: true,
			}
		},
		computed: {
			...mapGetters({
				state_product: 'PRODUCT/product',
				state_products: 'PRODUCT/products',
				state_selected_language: 'GLOBAL/selected_language',
			}),
		},
		watch: {
			state_selected_language: {
				handler(new_val, old_val) {
					if (new_val !== old_val) {
						const id = this.$router.currentRoute._value.params.slug
						this.$store.dispatch('PRODUCT/setSelectedProduct', id)
					}
				},
				deep: true,
			},
			$route(to, from) {
				if (
					to.params.slug !== from.params.slug &&
					to.name === 'product-detail'
				) {
					this.active_image = null
					this.active_video = null
					this.next_product = null
					this.prev_product = null
					this.can_show = false

					const id = Number(to.params.slug)
					this.$store
						.dispatch('PRODUCT/setSelectedProduct', id)
						.then(() => {
							this.active_image = this.state_product.images[0]

							if (
								this.state_product.videos &&
								this.state_product.videos.length > 0
							) {
								this.active_video = {
									title: this.state_product.videos[0].video,
									url: `https://www.youtube.com/embed/${this.state_product.videos[0].video}`,
								}
							}

							if (this.state_products) {
								const find_product_index =
									this.state_products.findIndex(
										(product) => product.id === id,
									)

								if (
									this.state_products[find_product_index + 1]
								) {
									this.next_product =
										this.state_products[
											find_product_index + 1
										].id
								}

								if (
									this.state_products[find_product_index - 1]
								) {
									this.prev_product =
										this.state_products[
											find_product_index - 1
										].id
								}
							} else {
								this.$store
									.dispatch('PRODUCT/getProducts')
									.then(() => {
										const find_product_index =
											this.state_products.findIndex(
												(product) => product.id === id,
											)

										if (
											this.state_products[
												find_product_index + 1
											]
										) {
											this.next_product =
												this.state_products[
													find_product_index + 1
												].id
										}

										if (
											this.state_products[
												find_product_index - 1
											]
										) {
											this.prev_product =
												this.state_products[
													find_product_index - 1
												].id
										}
									})
							}
							this.can_show = true
						})
				}
			},
		},
		methods: {
			handleActiveImage(swiper) {
				const active_image = swiper.imagesToLoad[swiper.activeIndex].src
				this.active_image = active_image
			},
			handleActiveVideo(swiper) {
				const active_slide_src =
					swiper.imagesToLoad[swiper.activeIndex].src
				const url_pathname = new URL(active_slide_src).pathname
				const youtube_id = url_pathname.split('/')[2]
				this.active_video.title = this.state_product.videos.find(
					(video) => video.video_id === youtube_id,
				)
				this.active_video.url = `https://www.youtube.com/embed/${youtube_id}`
			},
			init() {
				const id = this.$router.currentRoute._value.params.slug

				if (!this.state_product) {
					this.$store
						.dispatch('PRODUCT/setSelectedProduct', id)
						.then(() => {
							this.active_image = this.state_product.images[0]
							if (
								this.state_product.videos &&
								this.state_product.videos.length > 0
							) {
								this.active_video = {
									title: this.state_product.videos[0].video,
									url: `https://www.youtube.com/embed/${this.state_product.videos[0].video}`,
								}
							}
							this.can_show = true
						})
				} else {
					this.active_image = this.state_product.images[0]
					if (
						this.state_product.videos &&
						this.state_product.videos.length > 0
					) {
						this.active_video = {
							title: this.state_product.videos[0].video,
							url: `https://www.youtube.com/embed/${this.state_product.videos[0].video}`,
						}
					}
					this.can_show = true
				}

				this.$store.dispatch('PRODUCT/getProducts').then(() => {
					const find_product_index = this.state_products.findIndex(
						(product) => product.id === Number(id),
					)

					if (this.state_products[find_product_index + 1]) {
						this.next_product =
							this.state_products[find_product_index + 1].id
					}

					if (this.state_products[find_product_index - 1]) {
						this.prev_product =
							this.state_products[find_product_index - 1].id
					}
				})
			},
			navigateTo(id) {
				this.$router.push({
					name: 'product-detail',
					params: {
						slug: id,
					},
				})
			},
		},
		mounted() {
			this.init()
		},
	}
</script>

<style lang="scss" scoped>
	@import '../../assets/scss/product/product-detail.scss';
</style>
