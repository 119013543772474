import our_team_api from '../../../apis/our-team'

export default {
	async getOurTeam({ commit, rootGetters }) {
		const selected_language = rootGetters['GLOBAL/selected_language']
		const our_team = await our_team_api.getOurTeam(selected_language)

		commit('setOurTeam', our_team.data.data[0].description)
	},
}
