import our_client_api from '../../../apis/our-client'

export default {
	async getOurClients({ commit }) {
		const our_clients = await our_client_api.getOurClients()

		const api_uri =
			process.env.APP_ENV === 'development'
				? process.env.VUE_APP_API_URL_DEV
				: process.env.VUE_APP_API_URL_PROD

		if (our_clients.data.data.length > 0) {
			our_clients.data.data = our_clients.data.data.map((item) => ({
				...item,
				image: `${api_uri}${item.image}`,
			}))
		}

		commit('setOurClients', our_clients.data.data)
	},
}
