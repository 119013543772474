import { createApp } from 'vue'
import { createWebHistory, createRouter } from 'vue-router'
import store from './store'
import Multiselect from '@vueform/multiselect'
import routes from './router'

// styles

import '@fortawesome/fontawesome-free/css/all.min.css'
import '@vueform/multiselect/themes/default.css'
import './assets/css/vue-quill.snow.css'
import './assets/scss/style.scss'

// mouting point for the whole app

import App from '@/App.vue'

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior() {
		return {
			behavior: 'smooth',
			left: 0,
			top: 0,
		}
	},
})

createApp(App)
	.component('multiselect', Multiselect)
	.use(router)
	.use(store)
	.mixin({
		methods: {
			isEmpty(value) {
				return (
					value == null ||
					value.length === 0 ||
					value === undefined ||
					value === {} ||
					value === [] ||
					value === ''
				)
			},
		},
	})
	.mount('#app')
