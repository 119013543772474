<template>
	<div class="about-us">
		<main>
			<section class="detail" v-if="!is_loading.about">
				<template v-if="!isEmpty(state_about)">
					<h2 class="title">
						{{ about_menu.about_us[state_selected_language] }}
					</h2>
					<div
						class="description ql-editor"
						v-html="state_about.description"
					></div>
				</template>
				<div v-else class="no-data"></div>
			</section>
			<div class="loading" v-else>
				<i class="fas fa-circle-notch fa-spin"></i>
			</div>
			<section class="vision-mission" v-if="!is_loading.vision_mission">
				<template v-if="!isEmpty(state_vision_mission)">
					<div class="vision">
						<p class="title">
							{{ about_menu.vision[state_selected_language] }}
						</p>
						<div
							class="detail ql-editor"
							v-html="state_vision_mission.vision"
						></div>
					</div>
					<div class="mission" v-if="!isEmpty(state_vision_mission)">
						<p class="title">
							{{ about_menu.mission[state_selected_language] }}
						</p>
						<div
							class="detail ql-editor"
							v-html="state_vision_mission.mission"
						></div>
					</div>
				</template>
				<div v-else class="no-data"></div>
			</section>
			<div class="loading" v-else>
				<i class="fas fa-circle-notch fa-spin"></i>
			</div>
			<section class="specialist" v-if="!is_loading.specialist">
				<template v-if="!isEmpty(state_specialists)">
					<transition name="modal">
						<my-modal
							v-if="
								!is_specialist_hidden &&
								state_selected_specialist
							"
							@close="closeModalSpecialist()"
							:data="state_selected_specialist"
						>
						</my-modal>
					</transition>
					<h2 class="title">
						{{ about_menu.specialist[state_selected_language] }}
					</h2>
					<div class="slider">
						<span class="slider-navigation prev"></span>
						<swiper
							class="banner-slider"
							:preloadImages="true"
							:space-between="0"
							:autoplay="{
								delay: 2500,
								disableOnInteraction: false,
							}"
							:navigation="{
								nextEl: '.slider-navigation.next',
								prevEl: '.slider-navigation.prev',
							}"
							:breakpoints="{
								600: {
									slidesPerView: 1,
								},
								768: {
									slidesPerView: 2,
								},
								1024: {
									slidesPerView: 2,
								},
								1280: {
									slidesPerView: 3,
								},
							}"
						>
							<swiper-slide
								v-bind:key="`specialist-${slide.id}`"
								v-for="slide in state_specialists"
								class="banner-slide"
								@click.stop="openModalSpecialist(slide.id)"
							>
								<div
									class="banner-wrapper"
									:id="`specialist-${slide.id}`"
								>
									<div class="text-wrapper">
										<p class="title">
											{{ slide.title }}
										</p>
										<div
											class="detail ql-editor"
											v-html="slide.services"
										></div>
									</div>
									<div class="image-wrapper">
										<img
											class="banner-image"
											:src="slide.image"
										/>
										<span class="title">{{
											slide.title
										}}</span>
									</div>
								</div>
							</swiper-slide>
						</swiper>
						<span class="slider-navigation next"></span>
					</div>
				</template>
				<div v-else class="no-data"></div>
			</section>
			<div class="loading" v-else>
				<i class="fas fa-circle-notch fa-spin"></i>
			</div>
			<section class="our-team" v-if="!is_loading.our_team">
				<template v-if="!isEmpty(state_our_team)">
					<h2 class="title">
						{{ about_menu.our_team[state_selected_language] }}
					</h2>
					<div class="detail ql-editor" v-html="state_our_team"></div>
				</template>
				<div v-else class="no-data"></div>
			</section>
			<div class="loading" v-else>
				<i class="fas fa-circle-notch fa-spin"></i>
			</div>
			<section class="certificate" v-if="!is_loading.certificate">
				<template v-if="!isEmpty(state_our_certificates)">
					<h2 class="title">
						{{ about_menu.certificate[state_selected_language] }}
					</h2>
					<div class="slider">
						<span class="slider-navigation prev"></span>
						<swiper
							class="banner-slider certificate"
							:centeredSlides="true"
							:preloadImages="true"
							:space-between="0"
							:autoplay="{
								delay: 2500,
								disableOnInteraction: false,
							}"
							:navigation="{
								nextEl: '.slider-navigation.next',
								prevEl: '.slider-navigation.prev',
							}"
							:breakpoints="{
								600: {
									slidesPerView: 2,
								},
								768: {
									slidesPerView: 3,
								},
							}"
						>
							<swiper-slide
								v-bind:key="`certificate-${slide.id}`"
								v-for="slide in state_our_certificates"
								class="banner-slide"
							>
								<div
									class="banner-wrapper"
									:id="`certificate-${slide.id}`"
								>
									<img
										class="banner-image"
										:src="slide.image"
									/>
								</div>
							</swiper-slide>
						</swiper>
						<span class="slider-navigation next"></span>
					</div>
				</template>
				<div v-else class="no-data"></div>
			</section>
			<div class="loading" v-else>
				<i class="fas fa-circle-notch fa-spin"></i>
			</div>
		</main>
	</div>
</template>
<script>
	import { about } from '../config/menu.js'
	import { mapGetters } from 'vuex'
	import { Swiper, SwiperSlide } from 'swiper/vue'
	import SwiperCore, { Autoplay, Navigation } from 'swiper'
	SwiperCore.use([Autoplay, Navigation])
	import 'swiper/css'
	import 'swiper/css/navigation'

	// components
	import modal from '../components/partial/_Modal.vue'

	export default {
		components: {
			Swiper,
			SwiperSlide,
			'my-modal': modal,
		},
		data() {
			return {
				about_menu: about,
				is_specialist_hidden: true,
				is_loading: {
					about: true,
					certificate: true,
					our_team: true,
					specialist: true,
					vision_mission: true,
				},
			}
		},
		computed: {
			...mapGetters({
				state_about: 'ABOUT/about_data',
				state_our_certificates: 'OUR_CERTIFICATE/our_certificates',
				state_specialists: 'OUR_SPECIALIST/our_specialists',
				state_our_team: 'OUR_TEAM/our_team',
				state_selected_language: 'GLOBAL/selected_language',
				state_selected_specialist: 'OUR_SPECIALIST/selected_specialist',
				state_vision_mission: 'VISION_MISSION/vision_mission',
			}),
		},
		watch: {
			state_selected_language: {
				handler(new_val, old_val) {
					if (new_val !== old_val) {
						this.init()
					}
				},
				deep: true,
			},
		},
		methods: {
			closeModalSpecialist() {
				this.is_specialist_hidden = true
				this.$store.dispatch('OUR_SPECIALIST/removeSelectedSpecialist')
			},
			init() {
				this.$store.dispatch('ABOUT/getAbout')
				this.is_loading.about = false
				this.$store.dispatch('OUR_CERTIFICATE/getOurCertificates')
				this.is_loading.certificate = false
				this.$store.dispatch('OUR_SPECIALIST/getOurSpecialists')
				this.is_loading.specialist = false
				this.$store.dispatch('OUR_TEAM/getOurTeam')
				this.is_loading.our_team = false
				this.$store.dispatch('VISION_MISSION/getVisionMission')
				this.is_loading.vision_mission = false
			},
			openModalSpecialist(id) {
				this.is_specialist_hidden = false
				this.$store.dispatch('OUR_SPECIALIST/setSelectedSpecialist', id)
			},
		},
		mounted() {
			this.init()
		},
	}
</script>

<style lang="scss" scoped>
	@import '../assets/scss/about.scss';
</style>
