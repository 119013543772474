import api_config from '../config/api'

export default {
	async getProduct(id, language) {
		return api_config.get(`api/product_by_id/${language}/${id}`)
	},
	async getProducts(language) {
		return api_config.get(`api/product/${language}`)
	},
}
