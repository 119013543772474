<template>
	<div class="contact">
		<main>
			<section class="detail" v-if="!is_loading">
				<template v-if="!isEmpty(state_contact)">
					<h2 class="title">
						{{ contact_menu.contact[state_selected_language] }}
					</h2>
					<div class="detail-wrapper">
						<img :src="state_contact.image" />
						<div class="contact-wrapper">
							<div class="contact-col">
								<div class="contact pic">
									{{ state_contact.name }}
								</div>
								<div class="contact">
									<i class="fab fa-whatsapp"></i>
									<a
										:href="`https://wa.me/${state_contact.phone}`"
										target="_blank"
									>
										{{ state_contact.phone }}
									</a>
								</div>
								<div class="contact">
									<i class="fas fa-at"></i>
									<a
										:href="`mailto:${state_contact.mail}`"
										target="_blank"
									>
										{{ state_contact.mail }}
									</a>
								</div>
								<div class="contact">
									<i class="fab fa-linkedin"></i>
									<a
										:href="state_contact.linkedin"
										target="_blank"
									>
										{{ state_contact.name }}
									</a>
								</div>
							</div>
						</div>
					</div>
					<div class="address-wrapper">
						<div
							class="address ql-editor"
							v-html="state_contact.address"
						></div>
						<button @click="handleOpenMap()">
							<div
								class="button-location"
								:class="{
									eng: state_selected_language === 'eng',
								}"
							>
								<i class="fa fa-map-marker-alt"></i>
								<p>
									{{
										contact_menu.location[
											state_selected_language
										]
									}}
								</p>
							</div>
						</button>
					</div>
				</template>
				<div v-else class="no-data"></div>
			</section>
			<div class="loading" v-else>
				<i class="fas fa-circle-notch fa-spin"></i>
			</div>
		</main>
	</div>
</template>
<script>
	import { contact } from '../config/menu.js'
	import { mapGetters } from 'vuex'

	export default {
		data() {
			return {
				contact_menu: contact,
				is_loading: true,
			}
		},
		computed: {
			...mapGetters({
				state_contact: 'CONTACT/contact_data',
				state_selected_language: 'GLOBAL/selected_language',
			}),
		},
		watch: {
			state_selected_language: {
				handler(new_val, old_val) {
					if (new_val !== old_val) {
						this.$store.dispatch('CONTACT/getContact')
					}
				},
				deep: true,
			},
		},
		methods: {
			handleOpenMap() {
				window.open(this.state_contact.google_direction, '_blank')
			},
		},
		mounted() {
			this.$store.dispatch('CONTACT/getContact')
			this.is_loading = false
		},
	}
</script>

<style lang="scss" scoped>
	@import '../assets/scss/contact.scss';
</style>
