export default {
	removeSelectedSpecialist(state) {
		state.selected_specialist = null
	},
	setOurSpecialists(state, our_specialists) {
		state.our_specialists = our_specialists
	},
	setSelectedSpecialist(state, selected_specialist) {
		state.selected_specialist = selected_specialist
	},
}
