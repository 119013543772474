<template>
	<div>
		<nav
			class="
				top-0
				absolute
				z-50
				w-full
				flex flex-wrap
				items-center
				justify-between
				py-4
			"
			:class="{ 'scroll-down': scroll_position > 150 }"
		>
			<div
				class="
					px-4
					mx-auto
					flex flex-wrap
					items-center
					justify-between
					nav-wrapper
				"
			>
				<div
					class="
						w-full
						relative
						flex
						justify-between
						lg:w-auto lg:static lg:block lg:justify-start
					"
				>
					<router-link to="/">
						<img class="logo" :src="edim_logo" />
					</router-link>
					<button
						class="
							cursor-pointer
							text-black
							md:hidden
							px-3
							py-1
							text-xl
							leading-none
							bg-transparent
							rounded
							border border-solid border-transparent
							btn-burger
							open
						"
						type="button"
						:class="{ hidden: collapseShow !== 'hidden' }"
						v-on:click="
							toggleCollapseShow('bg-white m-2 py-3 px-6')
						"
					>
						<i class="fas fa-bars"></i>
					</button>
					<button
						type="button"
						class="
							btn-burger
							close
							cursor-pointer
							text-white
							px-3
							py-1
							text-xl
							leading-none
							bg-transparent
							rounded
							border border-solid border-transparent
							outline-0
						"
						:class="{ hidden: collapseShow === 'hidden' }"
						v-on:click="toggleCollapseShow('hidden')"
					>
						<i class="fas fa-times"></i>
					</button>
				</div>
				<div
					class="
						lg:flex
						flex-grow
						items-center
						lg:bg-transparent lg:shadow-none
					"
					v-bind:class="collapseShow"
				>
					<ul
						class="
							flex flex-col
							lg:flex-row
							list-none
							lg:ml-auto
							nav-menu
						"
					>
						<li class="flex items-center">
							<router-link
								class="menu"
								:class="{ active: currentRouteName === 'home' }"
								to="/"
								@click="toggleCollapseShow('hidden')"
								>{{
									navbar_menu.home[state_selected_language]
								}}</router-link
							>
						</li>
						<li class="flex items-center">
							<router-link
								class="menu"
								:class="{
									active:
										currentRouteName === 'product' ||
										currentRouteName === 'product-detail',
								}"
								to="/product"
								@click="toggleCollapseShow('hidden')"
								>{{
									navbar_menu.product[state_selected_language]
								}}</router-link
							>
						</li>
						<li class="flex items-center">
							<router-link
								class="menu"
								:class="{
									active: currentRouteName === 'about',
								}"
								to="/about"
								@click="toggleCollapseShow('hidden')"
								>{{
									navbar_menu.about[state_selected_language]
								}}</router-link
							>
						</li>
						<li class="flex items-center">
							<router-link
								class="menu"
								:class="{
									active: currentRouteName === 'contact',
								}"
								to="/contact"
								@click="toggleCollapseShow('hidden')"
								>{{
									navbar_menu.contact[state_selected_language]
								}}</router-link
							>
						</li>
						<li class="flex items-center">
							<router-link
								class="menu"
								:class="{
									active:
										currentRouteName === 'career' ||
										currentRouteName === 'career-detail',
								}"
								to="/career"
								@click="toggleCollapseShow('hidden')"
								>{{
									navbar_menu.career[state_selected_language]
								}}</router-link
							>
						</li>
						<li class="flex items-center">
							<multiselect
								:canClear="false"
								class="language-selector"
								v-model="language_selector.value"
								v-bind="language_selector"
							>
								<template v-slot:singlelabel="{ value }">
									<div class="selected-language">
										<img
											:class="`selected-language-icon ${value.value}`"
											:src="value.icon"
										/>
									</div>
								</template>

								<template v-slot:option="{ option }">
									<img
										class="language-option-icon"
										:src="option.icon"
									/>
								</template>
							</multiselect>
						</li>
					</ul>
				</div>
			</div>
		</nav>
		<section
			class="banner"
			v-if="!is_loading && currentRouteMeta.with_banner"
		>
			<template v-if="!isEmpty(state_banners)">
				<swiper
					class="banner-slider"
					:slides-per-view="1"
					:space-between="50"
					:navigation="true"
					:autoplay="{
						delay: 2500,
						disableOnInteraction: false,
					}"
				>
					<swiper-slide
						v-bind:key="`banner-${slide.id}`"
						v-for="slide in state_banners"
						class="banner-slide"
					>
						<img class="banner-image" :src="slide.image" />
					</swiper-slide>
				</swiper>
			</template>
			<div v-else class="no-data"></div>
		</section>
		<div
			class="loading"
			v-else-if="is_loading && currentRouteMeta.with_banner"
		>
			<i class="fas fa-circle-notch fa-spin"></i>
		</div>
	</div>
</template>
<script>
	import { mapGetters } from 'vuex'
	import { navbar } from '../config/menu.js'
	import { Swiper, SwiperSlide } from 'swiper/vue'
	import SwiperCore, { Autoplay, Navigation } from 'swiper'
	SwiperCore.use([Autoplay, Navigation])
	import 'swiper/css'
	import 'swiper/css/navigation'
	// assets
	import edim_logo from '../assets/img/edim-logo.png'
	import indonesia_flag from '../assets/img/indonesia-flag.svg'
	import us_flag from '../assets/img/united-states-flag.svg'
	export default {
		data() {
			return {
				collapseShow: 'hidden',
				edim_logo: edim_logo,
				is_loading: true,
				language_selector: {
					label: 'name',
					options: [
						{
							value: 'ind',
							name: 'Bahasa',
							icon: indonesia_flag,
						},
						{
							value: 'eng',
							name: 'Inggris',
							icon: us_flag,
						},
					],
					value: localStorage.getItem('selected_lang') || 'ind',
				},
				navbar_menu: navbar,
				scroll_position: null,
			}
		},
		components: {
			Swiper,
			SwiperSlide,
		},
		computed: {
			...mapGetters({
				state_banners: 'BANNER/banners',
				state_selected_language: 'GLOBAL/selected_language',
			}),
			currentRouteMeta() {
				return this.$route.meta
			},
			currentRouteName() {
				return this.$route.name
			},
		},
		methods: {
			scrollHeight() {
				this.scroll_position = window.scrollY
			},
			toggleCollapseShow(classes) {
				this.collapseShow = classes
			},
		},
		watch: {
			language_selector: {
				handler(new_val) {
					this.$store.dispatch('GLOBAL/setLanguage', new_val.value)
				},
				deep: true,
			},
		},
		mounted() {
			this.$store.dispatch('BANNER/getBanners')
			this.is_loading = false
		},
		created() {
			this.scroll_position = window.scrollY
			window.addEventListener('scroll', this.scrollHeight)
		},
		unmounted() {
			window.removeEventListener('scroll', this.scrollHeight)
		},
	}
</script>

<style lang="scss" scoped>
	@import '../assets/scss/template/_header.scss';
</style>
