import about_api from '../../../apis/about'

export default {
	async getAbout({ commit, rootGetters }) {
		const selected_language = rootGetters['GLOBAL/selected_language']
		const about_data = await about_api.getAbout(selected_language)

		commit('setAbout', about_data.data.data[0])
	},
}
