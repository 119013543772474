import our_specialist_api from '../../../apis/our-specialist'

export default {
	async getOurSpecialists({ commit, rootGetters }) {
		const selected_language = rootGetters['GLOBAL/selected_language']
		const our_specialists = await our_specialist_api.getOurSpecialists(
			selected_language,
		)

		const api_uri =
			process.env.APP_ENV === 'development'
				? process.env.VUE_APP_API_URL_DEV
				: process.env.VUE_APP_API_URL_PROD

		const temp = {}

		if (our_specialists.data.data.length > 0) {
			our_specialists.data.data.forEach((item) => {
				if (item.projects.length > 0) {
					item.projects = item.projects.map((project) => ({
						...project,
						image: `${api_uri}${project.image}`,
					}))
				}
				temp[item.id] = { ...item, image: `${api_uri}${item.image}` }
			})
		}

		commit('setOurSpecialists', temp)
	},
	removeSelectedSpecialist({ commit }) {
		commit('removeSelectedSpecialist')
	},
	setSelectedSpecialist({ commit, getters }, id) {
		const selected_specialist = getters.our_specialists[id]

		commit('setSelectedSpecialist', selected_specialist)
	},
}
