<template>
	<div class="modal-mask" v-on:click="$emit('close')">
		<div class="modal-wrapper" v-if="!isEmpty(contact_data)">
			<div class="modal-container" @click.stop="">
				<div class="modal-header">
					{{ footer_menu.info.detail.title[state_selected_language] }}
				</div>
				<div class="modal-body">
					<div class="contact">
						<a
							:href="`https://wa.me/${contact_data.phone}`"
							target="_blank"
						>
							Whatsapp
						</a>
						<i class="fab fa-whatsapp"></i>
					</div>
					<div class="contact">
						<a :href="`tel:${contact_data.phone}`" target="_blank">
							{{
								footer_menu.info.detail.phone[
									state_selected_language
								]
							}}
						</a>
						<i class="fas fa-phone"></i>
					</div>
					<div class="contact">
						<a
							:href="`mailto:${contact_data.mail}`"
							target="_blank"
						>
							Email
						</a>
						<i class="fas fa-at"></i>
					</div>
					<div class="contact">
						<a
							:href="contact_data.google_direction"
							target="_blank"
						>
							{{
								footer_menu.info.detail.address[
									state_selected_language
								]
							}}
						</a>
						<i class="fas fa-location-arrow"></i>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { footer } from '../../config/menu.js'
import { mapGetters } from 'vuex'

export default {
	emits: ['close'],
	props: ['contact_data'],
	data() {
		return {
			footer_menu: footer,
		}
	},
	computed: {
		...mapGetters({
			state_selected_language: 'GLOBAL/selected_language',
		}),
	},
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/template/partial/_modal-info.scss';
</style>