import category_api from '../../../apis/category'

export default {
	async getCategories({ commit, rootGetters }) {
		const selected_language = rootGetters['GLOBAL/selected_language']
		let categories_data = await category_api.getCategories(
			selected_language,
		)

		if (categories_data.data && categories_data.data.data) {
			categories_data.data.data = categories_data.data.data.map(
				(item) => ({
					name: item.name,
					value: item.id,
				}),
			)
		}

		commit('setCategories', categories_data.data?.data)
	},
}
