import api_config from '../config/api'

export default {
	getCatalog() {
		return api_config.get(`api/catalog/1`)
	},
	async updateCatalog({ id, form_data }, token) {
		return api_config.post(`api/catalog/${id}`, form_data, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
	},
}
