<template>
	<div class="product">
		<main>
			<section v-if="!is_loading" class="product-list">
				<h2 class="title">
					{{ product_menu.title[state_selected_language] }}
				</h2>
				<div class="action-wrapper">
					<div class="action-col left">
						<button
							class="btn-category"
							:class="{
								active:
									selected_category < 0 || !selected_category,
							}"
							@click="clearFilter"
						>
							{{
								product_menu.all_product[
									state_selected_language
								]
							}}
						</button>
						<multiselect
							class="btn-category category-selector"
							:class="{
								active:
									selected_category && selected_category > -1,
							}"
							:classes="select_category_classes"
							:placeholder="
								product_menu.category[state_selected_language]
							"
							v-model="selected_category"
							:options="state_categories"
							v-if="!isEmpty(state_categories)"
							@clear="clearFilter"
							@select="doFilter"
						>
							<template v-slot:singlelabel="{ value }">
								<div class="selected-category">
									{{ value.name }}
								</div>
							</template>
							<template v-slot:option="{ option }">
								{{ option.name }}
							</template>
						</multiselect>
					</div>
					<div
						class="action-col right"
						v-if="!isEmpty(state_catalog)"
					>
						<button
							class="btn-download"
							@click="handleDownloadCatalog"
						>
							<i class="fas fa-arrow-down"></i>
							{{ product_menu.download[state_selected_language] }}
						</button>
					</div>
				</div>
				<template v-if="!isEmpty(state_products)">
					<div
						class="product-col"
						v-if="paginate_products.length > 0"
					>
						<div
							v-bind:key="product.id"
							v-for="product in paginate_products"
							class="product-wrapper"
						>
							<img class="image" :src="product.images[0]" />
							<p class="category">{{ product.category_name }}</p>
							<p class="title">{{ product.name }}</p>
							<span
								class="btn-detail"
								@click="setSelectedProduct(product.id)"
							>
								<p class="text">Detail</p>
							</span>
						</div>
					</div>
					<div v-else class="no-product">
						{{ product_menu.no_product[state_selected_language] }}
					</div>
					<my-pagination
						:active_page="pagination_options.active_page"
						:page_length="pagination_options.page_length"
						:total_data="pagination_options.total_data"
						@clicked="doPaginate($event)"
						v-if="paginate_products.length > 0"
					/>
				</template>
				<div v-else class="no-data"></div>
			</section>
			<div class="loading" v-else>
				<i class="fas fa-circle-notch fa-spin"></i>
			</div>
		</main>
	</div>
</template>
<script>
	import { product } from '../../config/menu.js'
	import { mapGetters } from 'vuex'

	// components
	import Pagination from '../../components/partial/_Pagination.vue'

	export default {
		components: {
			'my-pagination': Pagination,
		},
		data() {
			return {
				filtered_product: [],
				is_loading: true,
				paginate_products: [],
				pagination_options: {
					active_page: 1,
					page_length: 5,
					total_data: null,
				},
				product_menu: product,
				select_category_classes: {
					placeholder: 'select-placeholder',
				},
				selected_category: null,
			}
		},
		computed: {
			...mapGetters({
				state_catalog: 'CATALOG/catalog',
				state_categories: 'CATEGORY/categories',
				state_products: 'PRODUCT/products',
				state_selected_language: 'GLOBAL/selected_language',
			}),
		},
		watch: {
			state_selected_language: {
				handler(new_val, old_val) {
					if (new_val !== old_val) {
						this.$store.dispatch('PRODUCT/getProducts').then(() => {
							this.doFilter()
						})
					}
				},
				deep: true,
			},
		},
		methods: {
			clearFilter() {
				this.selected_category = null
				this.doFilter()
			},
			doFilter() {
				if (!this.selected_category) {
					this.filtered_product = this.state_products
				} else {
					this.filtered_product = this.state_products.filter(
						(item) => item.category_id === this.selected_category,
					)
				}

				this.pagination_options.total_data =
					this.filtered_product.length
				this.paginate_products = this.filtered_product.filter(
					(_item, index) => {
						const start =
							(this.pagination_options.active_page - 1) *
							this.pagination_options.page_length
						const end =
							start + this.pagination_options.page_length - 1

						if (index >= start && index <= end) {
							return true
						}
						return false
					},
				)
			},
			doPaginate(page) {
				this.pagination_options.active_page = page
				this.paginate_products = this.filtered_product.filter(
					(_item, index) => {
						const start =
							(this.pagination_options.active_page - 1) *
							this.pagination_options.page_length
						const end =
							start + this.pagination_options.page_length - 1

						if (index >= start && index <= end) {
							return true
						}
						return false
					},
				)
			},
			handleDownloadCatalog() {
				window.open(this.state_catalog.files, '_blank')
			},
			init() {
				this.$store.dispatch('CATALOG/getCatalog')
				this.$store.dispatch('CATEGORY/getCategories')
				this.$store
					.dispatch('PRODUCT/getProducts')
					.then(() => {
						this.doFilter()
						this.is_loading = false
					})
					.catch(() => (this.is_loading = false))
			},
			setSelectedProduct(id) {
				this.$store
					.dispatch('PRODUCT/setSelectedProduct', id)
					.then(() => {
						this.$router.push({
							name: 'product-detail',
							params: {
								slug: id,
							},
						})
					})
			},
		},
		mounted() {
			this.init()
		},
	}
</script>

<style lang="scss" scoped>
	@import '../../assets/scss/product/product.scss';
</style>
